<template>
  <page :data="pageData" :is-child="_isChild" @refresh="refresh">
    <template #cmslefttype(vaddress-listitem)="{data: d}">
      <list-item
        :data="d.Data"
        @action-click="handleAction">
      </list-item>
    </template>
    <template #aftercmsleftcomponents>
      <address-modal v-bind="modalProps('address')" @close="closeModal('address')"></address-modal>
    </template>
  </page>
</template>

<script>
import CMSPage from '../base/CMSPage'
export default {
  extends: CMSPage,
  components: {
    ListItem: () => import('@/components/vaddress/ListItem'),
    AddressModal: () => import('./AddressModal')
  },
  mounted () {
    if (!this.user.IsLogin) {
      if (window.history.length > 1) return this.$router.replace('/user/dashboard')
      this.$router.push('/login')
    }
  },
  methods: {
    handleAction ({ action: a }) {
      if (a.Id === 'signout') {
        this.$buefy.dialog.confirm({
          message: this.config.LogoutMessage,
          type: 'is-primary',
          onConfirm: async () => {
            this.logout()
          }
      })
      } else if (a.Id === 'addresses') {
        this.openModal('address', {
          url: '/user/addresses'
        })
      } else if (a.Id === 'delete') {
        this.$buefy.dialog.confirm({
            message: this.config.DeleteMessage,
            type: 'is-primary',
            onConfirm: async () => {
              this.deleteAccount()
            }
        })
      }
    }
  }
}
</script>

<style>

</style>
